/* eslint-disable camelcase */

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { apolloClient } from '@/graphql/ApolloClient';
import indexedDBWrapper from '@/graphql/indexedDBWrapper';

import { clearAllCookies, getValueFromCookie } from './helpers';
import { LoginUserRole, CompanyType, CustomUser } from './typings';
import { LOCAL_STORAGE_REMOVABLE_KEYS } from '../constants/auth';

/**
 * This class acts as the central source of truth for accessing user related
 * auth information. It's helper methods are made accessible via `useAuth` hook.
 *
 * The `Auth` class can also be used independently outside the context
 * of react or if `useAuth` is not available (e.g. see ApolloProvider).
 *
 * In the future it may also provide access to permissions.
 * @see https://www.notion.so/shippioinc/202308_Difference-Between-FO-FOfW-FOfF-d574dcd931094a498ead24e51d28881c
 */
export class Auth {
  /**
   * Provided by Auth0 after login.
   */
  private auth0: Auth0ContextInterface<CustomUser>;
  /**
   * Represents the user of the current session.
   * (maybe undefined if no session exists)
   */
  private user?: CustomUser;

  /**
   * Whether or not the user is authenticated.
   * Because this depends on whether we have an auth0 session (non-staff)
   * or cookie session (shippio staff), we use it instead of auth0's isAuthenticated.
   */
  public isAuthenticated: boolean;

  constructor(auth0: Auth0ContextInterface<CustomUser>) {
    this.auth0 = auth0;
    this.user = auth0.user;
    this.isAuthenticated = (
      this.auth0.isAuthenticated || !!Auth.getAuthFromCookie().token
    );
  }

  /**
   * If the BO has set a cookie, this will return the auth info set inside the cookie.
   * It is used as an alternative means for shippio staff to authenticate our app.
   *
   * TODO: remove once BO switched to auth0
   */
  public static getAuthFromCookie = () => ({
    token: getValueFromCookie(`X-Auth-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`),
    role: getValueFromCookie(`X-Role-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`),
  })

  /**
   * this will return the currently used session token.
   * It is either an auth0 session (non-staff) or cookie session (shippio staff).
   *
   * If a staff token exists, it will take precedence over the auth0 token!
   */
  getToken = async () => {
    // internal mode session (staff)
    if (this.isStaffUser()) {
      return Auth.getAuthFromCookie().token;
    }

    // auth0 session
    if (this.auth0.isAuthenticated) {
      return (await this.auth0.getIdTokenClaims())!.__raw;
    }

    return undefined;
  }

  /**
   * @deprecated use one of the other helpers below
   */
  getLoginUserRole = () => (this.isStaffUser()
    ? LoginUserRole.Staff
    : this.user?.login_user_role)

  isCustomerUser = () => (this.user?.login_user_role === LoginUserRole.Customer);
  isStaffUser = () => Auth.getAuthFromCookie().role === LoginUserRole.Staff;
  isWarehouseUser = () => (this.user?.login_user_role === LoginUserRole.Warehouse);
  isSubsidiaryUser = () => (this.user?.company_type === CompanyType.Subsidiary);
  isForwarderUser = () => (
    this.user?.company_type === CompanyType.Forwarder
    // TODO: remove this once we fully transitioned to company types
    || (this.user?.login_user_role === LoginUserRole.Forwarder && !this.isSubsidiaryUser())
  )
  /**
   * A partner user is any user that is neither shippio staff nor a customer.
   * This includes forwarders, warehouses, subsidiaries...
   */
  isPartnerUser = () => this.user && !(this.isCustomerUser() || this.isStaffUser());

  /**
   * Performs several cleanups before logging out the user.
   * We use it as an override to auth0's logout.
   */
  logout = async () => {
    if (typeof localStorage !== 'undefined') {
      // TODO: better to use a whitelist, see https://shippio.atlassian.net/browse/TECH-288
      LOCAL_STORAGE_REMOVABLE_KEYS.forEach(
        (key) => localStorage.removeItem(key),
      );
    }

    // used because BO internal mode uses cookies
    // TODO: remove once BO switched to auth0
    clearAllCookies();

    apolloClient.stop();
    await apolloClient.clearStore();
    // TODO: this should not be necessary; fix apollo client in ApolloWrapper.jsx
    await indexedDBWrapper.clear();

    this.auth0.logout({ returnTo: window.location.origin });
  };
}
