export const CHANNEL_CONTEXT_TYPE = {
  Shipment: 'Shipment',
  QuoteLane: 'QuoteLane',
  ICP: 'IdentifiedCustomerPartner',
  Forwarder: 'Forwarder',
} as const;

export const CHANNEL_LIST_PER_QUERY = 30 as const;

export const CHANNEL_MESSAGE_TYPE = {
  file: 'file',
  text: 'text',
} as const;

export const DRAFT_CHAT_MESSAGES = 'draft-chat-messages' as const;

export const SENDER_TYPE = {
  Staff: 'Staff',
  CustomerUser: 'CustomerUser',
  CustomerPartnerUser: 'CustomerPartnerUser',
  ForwarderUser: 'ForwarderUser',
} as const;

export const PARTICIPANTS_TYPE = {
  staff_cpu: 'staff_cpu',
  staff_cu_cpu: 'staff_cu_cpu',
  staff_cu: 'staff_cu',
  cu_cu: 'cu_cu',
  CustomerUser_ForwarderUser: 'customerUser_forwarderUser',
} as const;

export const CHAT_ASSIGNEE_SELECTIONS = {
  assignedChats: 'assignedChats',
  allChats: 'allChats',
} as const;

export const STORAGE_ASSIGNED_CHAT = 'storage-assigned-chat' as const;
