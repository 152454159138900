import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

import { Auth } from '@/shared/utils/auth/auth';

import { CustomUser } from '../auth/typings';

/**
 * This hook returns all auth-related information and should
 * be used instead of `useAuth0`.
 *
 * It includes auth0 information and provides additional helpers
 * and overrides.
 */
export const useAuth = () => {
  const auth0 = useAuth0<CustomUser>();

  return useMemo(() => {
    const auth = new Auth(auth0);

    return {
      // spreading auth0 helpers for convenience all components
      // connecting to `useAuth` also have access to `useAuth0` information
      ...auth0,
      // next we spread our custom auth instance which contains helpers and
      // overrides e.g. for `logout` or `isAuthenticated`
      ...auth,
    };
  }, [auth0]);
};
