import { i18n as i18next } from 'i18next';

/**
 * Get the current language based of the site
 */
export const getCurrentLanguage = (i18n: i18next, fallback: string) => i18n?.language || localStorage.getItem('i18nextLng') || fallback;

export const getLocalStorageObject = <T = any>(
  storageKey: string,
  defaultValue?: T,
) => {
  const item = localStorage.getItem(storageKey);
  if (!item) {
    return defaultValue;
  }

  // Put in a try and catch just in case the local storage item is
  // not an object.
  try {
    return JSON.parse(item) as T;
  } catch (e) {
    return defaultValue;
  }
};

/**
 * Remove specific properties from the given object if it exists AND
 * is configurable
 */
export const removePropertiesFromObject = (
  context: Record<string, any>, propertiesToRemove: string[],
) => {
  const clone = { ...context };

  propertiesToRemove.forEach((prop) => {
    if (clone?.[prop]) {
      delete clone[prop];
    }
  });

  return clone;
};

/**
 * Creates a new object with only the specified key and value if present, else an empty object
 * @param search - url search param string
 * @param key - key (property name) of param to include in returned object
 */
export const getSearchParamPart = (search: string, key: string) => {
  const params = new URLSearchParams(search);
  return params.has(key) ? { [key]: params.get(key) } : {};
};

/**
 * Clamps a number to its own value or an upper / lower limit.
 * @param x - input number
 * @param min - minimum accepted value
 * @param max - maximum accepted value
 */
export const clamp = (
  x: number, min: number, max: number,
): number => Math.min(Math.max(x, min), max);

/**
 * Generate 32 digit hexadecimal UUID-like string, but without hyphens.
 * When in secure context (connected via https or to localhost)
 * uses the browser's built-in function intended for creating random UUIDs.
 * In other contexts, uses built-in function to generate 32 random digits;
 * @note the returned string is not a valid UUID
 */
export const randomID = (): string => {
  if (typeof window.crypto.randomUUID === 'function') {
    const hyphenatedUUID = window.crypto.randomUUID();
    return hyphenatedUUID.replace(/-/gi, '');
  }

  const uint8Array = new Uint8Array(16);
  window.crypto.getRandomValues(uint8Array);
  return [...uint8Array].map((v) => v.toString(16)).join('');
};

/*
 * parse input as integer or 0 if not parsable as integer
 * parse input as integer or return 0 if input is not parsable
 */
export const safeParseInt = (x: string) => parseInt(x, 10) || 0;

export const getMonthDiff = (d1: Date, d2: Date) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

/**
 * Return a list of month included within an interval
 * @param d1 - Start date of the interval
 * @param d2 - End date of the interval
 * @returns {[date]} - First day of each month within the interval
 */
export const getMonthListInDateInterval = (d1: Date, d2: Date) => {
  const dateList = [];
  const diff = getMonthDiff(d1, d2);

  for (let i = 0; i <= diff; i++) {
    const x = new Date(d2);

    x.setHours(0, 0, 0, 0);
    x.setDate(1);
    x.setMonth(x.getMonth() - i);

    dateList.push(new Date(x));
  }

  return dateList;
};
