import {
  HttpLink, ApolloLink, createHttpLink, Operation,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { DefinitionNode, OperationDefinitionNode } from 'graphql';

import { USER_TYPE, NON_STAFF_X_AUTH_ROLE } from '../shared/utils/constants/auth';
import { ERRORS } from '../shared/utils/constants/error';

/*
 * APOLLO CLIENT LINKS
 * Required actions (subset of functionality) in the client request flow
 */

const LOGOUT_ERROR_CODE = 'sign_in_required';

/**
 * Authorization Link -> setting the correct auth headers
 */
export const createHeadersAuthLink = (
  token: string, isStaffUser: boolean,
) => new ApolloLink((operation, forward) => {
  const prev = operation.getContext();
  const optionalHeaders = prev.optionalHeaders ?? {};

  operation.setContext({
    headers: {
      ...optionalHeaders,
      Authorization: `Bearer ${token}`,
      'X-Auth-Role': isStaffUser ? USER_TYPE.staff : NON_STAFF_X_AUTH_ROLE,
    },
  });
  if (forward) {
    return forward(operation);
  }

  return null;
});

const url = process.env.REACT_APP_APPSYNC_URL;
const region = 'ap-northeast-1';

/**
 * Create subscription link able to connect to AWS AppSync.
 * @note When `authToken` is `null`, the returned ApolloLink will not be able to
 * connect to AppSync.
 * @param {ID | null} authToken token obtained on login or null prior to login
 * @returns ApolloLink
 */
const createSubscriptionLink = (authToken: string) => {
  let auth;
  if (process.env.REACT_APP_ENV === 'development') {
    auth = {
      type: 'API_KEY',
      apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
    };
  } else {
    auth = {
      type: 'AWS_LAMBDA',
      token: authToken,
    };
  }
  const httpLink = createHttpLink({ uri: url });

  return ApolloLink.from([
    createAuthLink({ url: url as string, region, auth: auth as AuthOptions }),
    createSubscriptionHandshakeLink({
      url: url as string,
      region,
      auth: auth as AuthOptions,
    }, httpLink),
  ]);
};

/**
 * Error Link
 */
export const createErrorLink = (
  logout: () => Promise<void>,
) => onError(({ graphQLErrors, networkError }) => {
  if (networkError
      && 'statusCode' in networkError
      && networkError?.statusCode === 503
  ) {
    // This error is handled in ErrorBoundary component
    const error = new Error(ERRORS.maintenance.message);
    error.name = ERRORS.maintenance.name;
    throw error;
  }

  if (graphQLErrors) {
    if (graphQLErrors?.length && graphQLErrors[0]?.extensions?.code === LOGOUT_ERROR_CODE) {
      logout();
    } else {
    // eslint-disable-next-line no-console
      if (process.env.REACT_APP_ENV === 'development') console.log(graphQLErrors);

      if ((window as any).rollbar && graphQLErrors[0]?.extensions?.code !== 'login_failed') {
        (window as any).rollbar.error(
          graphQLErrors[0]?.extensions?.code || graphQLErrors[0]?.message,
          graphQLErrors,
        );
      }
    }
  }

  if (networkError) {
    const networkErrorString = JSON.stringify(networkError);
    // eslint-disable-next-line no-console
    if (process.env.REACT_APP_ENV !== 'production') console.log(`[Network error]: ${networkErrorString}`);

    if ((window as any).rollbar) {
      (window as any).rollbar.warn(`[Network error]: ${networkErrorString}`);
    }
  }
});

const customFetch = (uri: string, options: any) => {
  const json = JSON.parse(options.body);
  const operationName = Array.isArray(json) ? json[0].operationName : json.operationName;
  return fetch(`${uri}?op=${operationName}`, options);
};

/**
 * Terminating Link => make sure this link is last in the chain

 * Create the batch link which batches together individual operations
 * into an array that is sent to a single GraphQL endpoint.
 * https://www.apollographql.com/docs/link/links/batch-http/
 */
const BATCH_LINK = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  fetch: customFetch,
  headers: { batch: 'true' },
  // batchMax <- we can set this, default is 10, is this OK?
});

/**
 * Create the normal http link for regular requests
 * https://www.apollographql.com/docs/link/links/http/
 */
const SINGLE_HTTP_LINK = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  fetch: customFetch,
});

/**
 * Subscription link for web sockets. Used Action Cable as that is being used on the BE
 *
 * Split function that checks if the operation type is a subscription
 * https://graphql-ruby.org/javascript_client/apollo_subscriptions#apollo-2--actioncable
 */
const hasSubscriptionOperation = (op: Operation) => op.query.definitions.some((
  definition: DefinitionNode,
) => (
  definition.kind === 'OperationDefinition'
      && (definition as OperationDefinitionNode).operation === 'subscription'
));

/**
 * Allow specifying on the query level if the query should be a batch request or a.
 * regular single http request. For this we use split, which allows us to return
 * a certain link depending on the given context of the query
 * https://www.apollographql.com/docs/link/composition/#directional-composition
 * https://www.apollographql.com/blog/batching-client-graphql-queries-a685f5bcd41b/
 * https://www.codota.com/code/javascript/functions/apollo-link/split?snippet=5f621c3343564f2b5cc7d704
 */
const HTTP_LINKS = ApolloLink.split(
  (operation) => operation.getContext().batchQuery === true,
  BATCH_LINK,
  // if operation context batch query is specified true, then batch the request
  SINGLE_HTTP_LINK, // else send through a regular singular http link
);

/**
 * Create main link that handles subscriptions, batch queries, and all other queries.
 * When `authToken` is `null` the returned links will be able to handle the latter
 * two operation groups, but not subscriptions.
 * @param {ID | null} authToken token obtained on login or null prior to login
 * @returns ApolloLink
 */
export const createMainLink = (token: string) => {
  const SUBSCRIPTION_LINK = createSubscriptionLink(token);
  /**
   * Use directional composition so Apollo can decide whether to use the subscription link
   * for web sockets (subscriptions) or normal http requests (which is another split)
   * https://www.apollographql.com/docs/link/composition/#directional-composition
   */
  return ApolloLink.split(
    hasSubscriptionOperation,
    SUBSCRIPTION_LINK, // if operation is a subscription, use AppSync subscription link
    HTTP_LINKS, // else figure out if we need a standard HTTP or batch request
  );
};
