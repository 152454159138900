import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Rollbar from 'rollbar';

import { setUpDatadog } from '@/lib/datadog';
import { setUpGTM } from '@/lib/gtm';
import { useAuth } from '@/shared/utils/hooks/useAuth';
import { getCurrentLanguage } from '@/shared/utils/utils';

import AppFallback from './components/AppFallback/AppFallback';
import ErrorBoundary from '../components/ErrorBoundary';
import frontTheme from '../shared/theme/frontTheme';
import { LANGUAGE_OPTIONS } from '../shared/utils/constants/settings';

// Lazy load in applications
const AuthenticatedApp = React.lazy(() => import('./AppAuthenticated'));
const StaffApp = React.lazy(() => import('./AppAuthenticatedStaff'));

function App() {
  const { i18n } = useTranslation();
  const {
    isAuthenticated, isLoading, error, loginWithRedirect, isStaffUser,
  } = useAuth();

  useEffect(() => {
    setUpDatadog();
    setUpGTM();
  }, []);

  const getApp = () => {
    if (isLoading) {
      // Without this 'isLoading' condition, it loops and returns to the login screen.
      return true;
    }

    if (isStaffUser()) {
      return (<StaffApp />);
    }

    if (!isAuthenticated || error) {
      loginWithRedirect({ redirectUri: window.location.origin, ui_locales: getCurrentLanguage() });
      return null;
    }

    return (<AuthenticatedApp />);
  };

  useEffect(() => {
    window.rollbar = null;
    if (!window.rollbar) {
      // Rollbar config is here
      const rollbar = new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: process.env.NODE_ENV === 'production',
        hostSafeList: [
          'app2.shippio.io',
          'staging.app2.shippio.io',
          'demo.app2.shippio.io',
          'app.shippio.jp',
          'app.stg.shippio.jp',
          'demo-app.shippio.jp',
        ],
        payload: {
          environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
        },
        checkIgnore: (_isUncaught, args) => {
          // Code here to determine whether or not to send the payload
          // to the Rollbar API
          // return true to ignore the payload
          if (args[1]?.name === 'ChunkLoadError') {
            return true;
          }

          return false;
        },
      });
      window.rollbar = rollbar;
    }

    return () => {
      window.rollbar = null;
    };
  }, []);

  useEffect(() => {
    const { language = LANGUAGE_OPTIONS.ja } = i18n;
    document.documentElement.lang = language;
  }, [i18n, i18n.language]);

  return (
    // This outer div is necessary to keep the notification popups in position
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={frontTheme}>
          {/*
              Currently wrapping lazy loading routes with the test Error Boundary, although
              probably good to wrap the entire application with something like this
            */}
          <ErrorBoundary>
            <Suspense fallback={<AppFallback authenticated={isAuthenticated} />}>
              {/*
                  The idea here is lazy load the authenticated and unauthenticated logic,
                  might have benefits on loading the initial app for logging in.
                  It will also immediately switch to the unauthenticated app upon change in
                  authenticated state from the auth context provider so less use of <Redirect>
                */}
              {getApp()}
            </Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
