import { chat } from '@/shared/redux/chat/reducers';
import { noticeListSlice } from '@/shared/redux/noticeListSlice';
import { shipments } from '@/shared/redux/shipments/reducers';
import { uiEvents } from '@/shared/redux/uiEvents/reducers';
import { user } from '@/shared/redux/user/reducers';

export const sharedReducers = {
  chat,
  noticeList: noticeListSlice.reducer,
  shipments,
  uiEvents,
  user,
} as const;

export type AppState = {
  [name in keyof typeof sharedReducers]: ReturnType<typeof sharedReducers[name]>;
};

export const devTools = process.env.REACT_APP_ENV !== 'production' && {
  trace: true, // see the call stack that has triggered the action
  traceLimit: 25, // Limit the number of actions shown in the trace
};
